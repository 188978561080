<template>
  <BaseNavItem :title="$t('quickDeal')" icon="$vuetify.icons.values.add" :route="targetRoute" :visible="true" />
</template>

<script>
import { mapState } from 'vuex'
import { BaseNavItem } from '@argon/app/components'

export default {
  name: 'NewIndividualLead',
  components: { BaseNavItem },
  computed: {
    targetRoute() {
      return { name: 'newLead' }
    }
  }
}
</script>
<i18n>
{
  "en": {"quickDeal": "Quick Lead"},
  "fr": {"quickDeal": "Référence rapide"}
}</i18n>
